<template>
	<div class="px-4 w-full dF fC f1 pb-4 hide-scrollbar" style="overflow-y: scroll">
		<div class="dF aC">
			<a-icon type="arrow-left" @click="$router.go(-1)" />
		</div>
		<div>
			<a-tabs size="large" default-active-key="1">
				<div slot="tabBarExtraContent" class="dF" style="gap: 20px">
					<div class="dF" style="gap: 5px">
						<span>Download:</span>
						<a @click.prevent="getCSV(dataRecords)" href="javascript:;" class="text-primary">CSV</a>
					</div>
				</div>
				<a-tab-pane key="1" tab="Pipeline Details">
					<ChartCard :details="selectedChart" :loading="loading" />
				</a-tab-pane>
			</a-tabs>
			<a-card style="margin-top: 50px" class="table-card">
				<a-table class="tableStyle" :columns="columns" :data-source="dataRecords" :row-key="(record) => record.id"
					:scroll="{ x: 1200 }">
					<template #createdDate="createdDate">
						{{ formatDate(createdDate) }}
					</template>
					<template #closeDate="closeDate">
						{{ formatDate(closeDate) }}
					</template>
					<template #dueDate="dueDate">
						{{ formatDate(dueDate) }}
					</template>
					<template #priority="priority">
						{{ priority.toUpperCase() }}
					</template>
					<template #value="value">
						$ {{ value.toLocaleString("en-US") }}
					</template>
				</a-table>
			</a-card>
		</div>
	</div>
</template>

<script>
import ChartCard from "@/components/charts/chartCard";
import { mapState } from "vuex";
import moment from "moment";
import { formatDate } from 'bh-mod'

export default {
	components: {
		ChartCard,
	},
	data: () => ({
		loading: false,
		dataRecords: [],
	}),

	computed: {
		...mapState(["records", "instance"]),

		stages() {
			const settings = this.$store.state.reports.allSettings;
			return settings.app && settings.app.options && settings.app.options.stages && settings.app.options.stages.list || []
		},

		selectedChart() {
			const chartDetails = this.$store.state.selectedChart;
			if (chartDetails.title === 'Pipeline by stage' && this.stages && this.stages.length) {
				chartDetails.option.xAxis.data = this.stages.map(s => s?.name);
			}
			return chartDetails
		},

		columns() {
			return this.getColumns();
		},

		filename() {
			return `Pipeline Report(${moment(this.currentStartDate).format(
				"ll"
			)} - ${moment(this.currentEndDate).format("ll")})`;
		},

		corporateInstance() {
			return (this.instance && this.instance.projectType === 'corporate' && this.instance.children.length)
		},

		selectedProjects() {
			return this.$store.state.selectedProjects
		},
	},

	created() {
		this.loading = false;
	},

	methods: {
		formatDate,

		getColumns() {
			if (this.selectedChart.title === "Sales rep activity") {
				this.dataRecords = this.records.pipeline.tasks.map((t, i) => {
					return {
						id: i,
						...t,
						dueDate: t.dueDate && formatDate(t.dueDate) || "",
					}
				});
				if (this.corporateInstance) {
					this.dataRecords = this.dataRecords.filter(r => this.selectedProjects.includes(r.instanceName))
				}

				return [
					{
						title: "Project",
						dataIndex: "instanceName",
						key: "instanceName",
						sorter: (a, b) =>
							a.instanceName < b.instanceName ? -1 : a.instanceName > b.instanceName ? 1 : 0,
					},
					{
						title: "Title",
						dataIndex: "title",
						key: "title",
						sorter: (a, b) =>
							a.title < b.title ? -1 : a.title > b.title ? 1 : 0,
					},
					{
						title: "Task Type",
						dataIndex: "type",
						key: "type",
						sorter: (a, b) =>
							a.type < b.type ? -1 : a.type > b.type ? 1 : 0,
					},
					{
						title: "Priority Level",
						dataIndex: "priority",
						key: "priority",
						scopedSlots: { customRender: "priority" },
						sorter: (a, b) =>
							a.priority < b.priority
								? -1
								: a.priority > b.priority
									? 1
									: 0,
					},
					{
						title: "Primary Contact",
						dataIndex: "contact",
						key: "contact",
						sorter: (a, b) =>
							a.contact < b.contact
								? -1
								: a.contact > b.contact
									? 1
									: 0,
					},
					{
						title: "Task Owner",
						dataIndex: "owner",
						key: "owner",
						sorter: (a, b) =>
							a.owner < b.owner ? -1 : a.owner > b.owner ? 1 : 0,
					},
					{
						title: "Assign To",
						dataIndex: "assignTo",
						key: "assignTo",
						sorter: (a, b) =>
							a.assignTo < b.assignTo ? -1 : a.assignTo > b.assignTo ? 1 : 0,
					},
					{
						title: "Due Date",
						dataIndex: "dueDate",
						key: "dueDate",
						scopedSlots: { customRender: "dueDate" },
						sorter: (a, b) => a.dueDate - b.dueDate,
					},
				];
			}
			this.dataRecords = this.records.pipeline.all.map((t) => {
				return {
					...t,
					createdDate: t.createdDate && formatDate(t.createdDate) || "",
					closeDate: t.closeDate && formatDate(t.closeDate) || "",
				}
			});
			if (this.corporateInstance) {
				this.dataRecords = this.dataRecords.filter(r => this.selectedProjects.includes(r.instanceName))
			}

			return [
				{
					title: "Project",
					dataIndex: "instanceName",
					key: "instanceName",
					sorter: (a, b) =>
						a.instanceName < b.instanceName ? -1 : a.instanceName > b.instanceName ? 1 : 0,
				},
				{
					title: "Opportunity Name",
					dataIndex: "name",
					key: "name",
					sorter: (a, b) =>
						a.name < b.name ? -1 : a.name > b.name ? 1 : 0,
				},
				{
					title: "Opportunity Owner",
					dataIndex: "owner",
					key: "owner",
					sorter: (a, b) =>
						a.owner < b.owner ? -1 : a.owner > b.owner ? 1 : 0,
				},
				{
					title: "Opportunity Value",
					dataIndex: "value",
					key: "value",
					scopedSlots: { customRender: "value" },
					sorter: (a, b) => a.value - b.value,
				},
				{
					title: "Opportunity Created Date",
					dataIndex: "createdDate",
					key: "createdDate",
					scopedSlots: { customRender: "createdDate" },
					sorter: (a, b) =>
						moment(a.createdDate).format("x") -
						moment(b.createdDate).format("x"),
				},
				{
					title: "Opportunity Stages",
					dataIndex: "stages",
					key: "stages",
					sorter: (a, b) =>
						a.stages < b.stages ? -1 : a.stages > b.stages ? 1 : 0,
				},
				{
					title: "Opportunity Source",
					dataIndex: "source",
					key: "source",
					sorter: (a, b) =>
						a.source < b.source ? -1 : a.source > b.source ? 1 : 0,
				},
				{
					title: "Estimated Close Date",
					dataIndex: "closeDate",
					key: "closeDate",
					scopedSlots: { customRender: "closeDate" },
					sorter: (a, b) =>
						moment(a.closeDate).format("x") -
						moment(b.closeDate).format("x"),
				},
				{
					title: "Lost Reasons",
					dataIndex: "lostReasons",
					key: "lostReasons",
					sorter: (a, b) =>
						a.lostReasons < b.lostReasons
							? -1
							: a.lostReasons > b.lostReasons
								? 1
								: 0,
				},
			];
		},

		getCSV(records) {
			if (records.length) {
				let csv = Object.keys(records[0]).join(",");
				csv += "\n";

				records.forEach((row) => {
					csv += Object.values(row)
						.map((r) => {
							if (typeof r === "string") {
								return r.replaceAll(", ", " & ");
							}
							return r;
						})
						.join(",");
					csv += "\n";
				});

				const anchor = document.createElement("a");
				anchor.href =
					"data:text/csv;charset=utf-8," + encodeURIComponent(csv);
				anchor.target = "_blank";
				anchor.download = `${this.filename}.csv`;
				anchor.click();
			} else {
				this.$message.info("No records found!");
			}
		},
	},
};
</script>

<style>
</style>
